import {
  SdBusinessCallbackActions,
  SetInputValidated,
  SET_INPUT_SD_BUSINESS_CALL_BACK,
  SHOW_SECOND_STEP_SD_BUSINESS_CALL_BACK,
  SET_FORM_LOADING_SD_BUSINESS_CALL_BACK,
  SetFormLoading,
  SET_PHONE_INPUT_SD_BUSINESS_CALL_BACK,
  SetPhoneInputValidated,
  SET_FORM_SUCCESS_SD_BUSINESS_CALL_BACK,
  SetFormSuccess,
  SetGeneralErrorMessage,
  SET_GENERAL_ERROR_SD_BUSINESS_CALL_BACK,
  RESET_FORM_SD_BUSINESS_CALL_BACK,
  SET_WRONG_ACC_NUMBER_SD_BUSINESS_CB,
  SetWrongAccNumber,
} from './actions';

export type SdBusinessCallbackData = {
  isLoading: boolean;
  isSuccess: boolean;
  isSecondStep: boolean;
  generalError: {
    message?: string;
  };
  isWrongAccountNumber: boolean;
  accountNumber: {
    value: string;
    error: string;
  };
  recaptcha: {
    value: string;
    error: string;
  };
  name: {
    value: string;
    error: string;
  };
  phoneNumber: {
    countryCode: string;
    number: string;
    error: string;
  };
  email: {
    value: string;
    error: string;
  };
  reason: {
    value: string;
    error: string;
  };
}

export const initialState: SdBusinessCallbackData = {
  isLoading: false,
  isSuccess: false,
  isSecondStep: false,
  generalError: {
    message: '',
  },
  isWrongAccountNumber: false,
  accountNumber: {
    value: '',
    error: '',
  },
  recaptcha: {
    value: '',
    error: '',
  },
  name: {
    value: '',
    error: '',
  },
  phoneNumber: {
    countryCode: '+971',
    number: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  reason: {
    value: '',
    error: '',
  },
};

const SdBusinessCallback = (
  state: SdBusinessCallbackData = initialState,
  action: SdBusinessCallbackActions,
): SdBusinessCallbackData => {
  switch (action.type) {
    case SET_INPUT_SD_BUSINESS_CALL_BACK:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_PHONE_INPUT_SD_BUSINESS_CALL_BACK:
      return {
        ...state,
        ...(action as SetPhoneInputValidated).payload,
      };
    case SET_FORM_LOADING_SD_BUSINESS_CALL_BACK: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_SD_BUSINESS_CALL_BACK: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SHOW_SECOND_STEP_SD_BUSINESS_CALL_BACK:
      return {
        ...state,
        isSecondStep: true,
      };
    case SET_GENERAL_ERROR_SD_BUSINESS_CALL_BACK: {
      return {
        ...state,
        generalError: {
          message: (action as SetGeneralErrorMessage).payload,
        },
      };
    }
    case RESET_FORM_SD_BUSINESS_CALL_BACK: {
      return initialState;
    }
    case SET_WRONG_ACC_NUMBER_SD_BUSINESS_CB: {
      return {
        ...state,
        isWrongAccountNumber: (action as SetWrongAccNumber).payload,
      };
    }
    default:
      return state;
  }
};

export default SdBusinessCallback;
