import {
  validateEmail,
  validateRequired,
  Validator,
} from 'application/utils/validators';

// eslint-disable-next-line max-len
export const SUBMIT_FIRST_STEP_SD_BUSINESS_CALL_BACK = '[form] submit 1st step sd business call back';
export type SubmitFirstStep = {
  type: typeof SUBMIT_FIRST_STEP_SD_BUSINESS_CALL_BACK;
}
export const submitFirstStep = {
  type: SUBMIT_FIRST_STEP_SD_BUSINESS_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SUBMIT_SECOND_STEP_SD_BUSINESS_CALL_BACK = '[form] submit 2nd step sd business call back';
export type SubmitSecondStep = {
  type: typeof SUBMIT_SECOND_STEP_SD_BUSINESS_CALL_BACK;
}
export const submitSecondStep = {
  type: SUBMIT_SECOND_STEP_SD_BUSINESS_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_SD_BUSINESS_CALL_BACK = '[forms] set loading sd business call back';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_SD_BUSINESS_CALL_BACK;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_SD_BUSINESS_CALL_BACK,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_SD_BUSINESS_CALL_BACK = '[forms] set success sd business call back';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_SD_BUSINESS_CALL_BACK;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_SD_BUSINESS_CALL_BACK,
  payload: {
    isSuccess,
  },
});

export const RESET_FORM_SD_BUSINESS_CALL_BACK = '[sd-business] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_SD_BUSINESS_CALL_BACK,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_SD_BUSINESS_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SET_INPUT_SD_BUSINESS_CALL_BACK = '[forms] input change sd business call back';

export type SetInput = {
  type: typeof SET_INPUT_SD_BUSINESS_CALL_BACK;
  payload: {
    fieldName: string;
    value: string;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_SD_BUSINESS_CALL_BACK;
  payload: {
    [fieldName: string]: {
      value: string;
      error: string;
    }
  }
}

export const setRequiredInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SD_BUSINESS_CALL_BACK,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});

export const setRequiredEmail = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SD_BUSINESS_CALL_BACK,
  payload: {
    fieldName,
    value,
    validators: [validateRequired, validateEmail],
  },
});

// eslint-disable-next-line max-len
export const SET_PHONE_INPUT_SD_BUSINESS_CALL_BACK = '[forms] phone number change sd business call back';
export type SetPhoneInput = {
  type: typeof SET_PHONE_INPUT_SD_BUSINESS_CALL_BACK;
  payload: {
    countryCode: string;
    number: string;
    isRequired: boolean;
  }
}
export type SetPhoneInputValidated = {
  type: typeof SET_PHONE_INPUT_SD_BUSINESS_CALL_BACK;
  payload: {
    phoneNumber: {
      countryCode: string;
      number: string;
      error: string;
    };
  }
}

export const setRequiredPhoneInput = (
  countryCode: string,
  number: string,
): SetPhoneInput => ({
  type: SET_PHONE_INPUT_SD_BUSINESS_CALL_BACK,
  payload: {
    countryCode,
    number,
    isRequired: true,
  },
});

// eslint-disable-next-line max-len
export const SET_GENERAL_ERROR_SD_BUSINESS_CALL_BACK = 'set general error sd business callback';
export type SetGeneralErrorMessage = {
  type: typeof SET_GENERAL_ERROR_SD_BUSINESS_CALL_BACK;
  payload: string;
}

export const setGeneralErrorMessage = (
  message: SetGeneralErrorMessage['payload'],
): SetGeneralErrorMessage => ({
  type: SET_GENERAL_ERROR_SD_BUSINESS_CALL_BACK,
  payload: message,
});

// eslint-disable-next-line max-len
export const SHOW_SECOND_STEP_SD_BUSINESS_CALL_BACK = '[forms] show 2nd step sd business call back';

export type ShowSecondStep = {
  type: typeof SHOW_SECOND_STEP_SD_BUSINESS_CALL_BACK,
};
export const showSecondStep = {
  type: SHOW_SECOND_STEP_SD_BUSINESS_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SET_WRONG_ACC_NUMBER_SD_BUSINESS_CB = '[forms] set wrong acc number sd business callback';
export type SetWrongAccNumber = {
  type: typeof SET_WRONG_ACC_NUMBER_SD_BUSINESS_CB,
  payload: boolean;
};
export const setWrongAccNumber = (isWrong: boolean): SetWrongAccNumber => ({
  type: SET_WRONG_ACC_NUMBER_SD_BUSINESS_CB,
  payload: isWrong,
});

export type SdBusinessCallbackActions =
| SetInputValidated
| SetPhoneInputValidated
| SetFormLoading
| SetFormSuccess
| ShowSecondStep
| SetGeneralErrorMessage
| ResetForm
| SetWrongAccNumber;
